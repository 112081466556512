import React from 'react';
import communitybg from '../../img/CommunityBg.png'
import { Headertwo } from '../Text/TextsElements';

import { 

    CommunityContainer,
    CommunityBg,
    CommunityImg,
    InfoRow,
    Column2,
    TextWrapper,
    ButtonContainer,
    CommunityButtonContainerLabel,
    ButtonLink,

 } from './CommunityElements'

const CommunitySection = () => {
    return (
            
            <CommunityContainer id="Community">
               
                        <InfoRow>
                            <Column2>
                                <TextWrapper>
                                    <Headertwo>JOIN THE COMMUNITY</Headertwo>
                                </TextWrapper>
                                <ButtonLink href="https://discord.com/invite/Mekaformers" target="_blank" rel="noreferrer" >
                                    <ButtonContainer>
                                        <CommunityButtonContainerLabel>Join our Discord</CommunityButtonContainerLabel>
                                    </ButtonContainer>
                                </ButtonLink>
                            </Column2>
                        </InfoRow>
                 
                <CommunityBg>
                    <CommunityImg src={communitybg} type='CommunityBg/png'/>
                </CommunityBg>

                </CommunityContainer>
            
    )
}

export default CommunitySection
