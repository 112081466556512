
export const Welcome ={
    id: 'Welcome',
    headingh1: '1,500 Mekaformers',
    headingh2: 'That are ready to fight',
    pone: 'Mekaformers are a collection with 1,500 generative robots with hundreds of elements that make each one of them truly unique and incredibly epic.',
    ptwo: 'Each robot is a masterpiece itself, including different animations and sounds among their traits. Mekaformers are metaverse-ready and game-ready assets, coming straight from Unreal Engine 5.',
    img: require('../../img/welcomeGIF.gif').default,
    alt: 'welcome to Sack It! Society',
};

