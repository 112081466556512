import React from 'react'

import WelcomeSection from '../components/Welcome/Index';
import Hero from '../components/hero/Index.js';
import { Welcome } from '../components/Welcome/WelcomeData';
import Roadmap from '../components/Roadmap/Index';
import FooterSection from '../components/Footer/Index';
import Gallery from '../components/Carousel/Gallery';
import Team from '../components/Team/Index';
import CommunitySection from '../components/Community/Index';

const Home = () => {
    return (
        <>
            <Hero/>
            <WelcomeSection {...Welcome}/>
            <Gallery/>
            <Roadmap/>
            <Team/>
            <CommunitySection/>
            <FooterSection/>
        </>
    )
}

export default Home
