import styled from "styled-components";

export const HeaderOne = styled.h1`
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    line-height: 59px;
    color: #FFFFFF;
    
    @media screen and (max-width: 960px){  
        font-size: 30px;
        line-height: 35px; 
    }  
    
    @media screen and (max-width: 380px){  
        font-size: 30px;
        line-height: 35px;
    } 
`;

export const HeaderOneGreen = styled.h1`
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    line-height: 59px;
    color: #5FF35C;
    margin-bottom: -1px;
    
    @media screen and (max-width: 960px){  
        font-size: 30px;
        line-height: 35px; 
    }  
    
    @media screen and (max-width: 380px){  
        font-size: 30px;
        line-height: 35px;
    } 
`;


export const Headertwo = styled.h2`
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 125.19%;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom:24px;
`

export const Headerthree = styled.h3`
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 30px;
    display: flex;
    align-items: flex-end;
    color: #FFFFFF;
`

export const ParagraphOne = styled.p`
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 19px;
line-height: 24px;
color: #8E96A6;
margin-bottom: 32px;
`

export const ParagraphTwo = styled.p`
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 25px;
color: #8E96A6;
margin-bottom: 16px;
`

export const ParagraphTwoCenter = styled.p`
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 25px;
color: #8E96A6;
margin-bottom: 16px;
text-align: center;
`

export const Number = styled.p`

font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 17px;
line-height: 26px;
display: flex;
align-items: flex-end;
color: #5FF35C;
`

