import styled from "styled-components";


export const FooterContainer = styled.div`
background: #040404;
padding: 80px 24px;
flex-direction: column;
display:flex;
align-items: center;    
width:100%;

`

export const Logo=styled.div`
    position: static;
    width: 241px;
    height: 34px;
    left: 0px;
    top: 0px;

    @media screen and (max-width: 960px){  
        height: 48px;
        width: 200px;
    }
    @media screen and (max-width: 380px){  
        height: 24px;
        width: 200px;
    } 

    
`

export const Logoimg=styled.img`
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
`
