import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { render } from "react-dom";
import Downloads from './pages/downloads';
import Mint from './pages/mint';
import MintHolders from './pages/holders';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

const rootElement = document.getElementById("root");

render(
  <BrowserRouter>
    <Routes>
        <Route path="/" element={<App />} />
        <Route path="downloads" element={<Downloads />} />
        <Route path="/legacy/mint" element={<Mint />} />
        <Route path="/legacy/holders-mint" element={<MintHolders />} />
    </Routes>
  </BrowserRouter>,
  rootElement
);
