import React from 'react'
import Mekaformer from '../components/Mekaformer/index';

const Downloads = () => {
    return (
        <>
            <Mekaformer/>
        </>
    )
}

export default Downloads;
