import styled from "styled-components";

export const TeamContainer = styled.div`
    color: #fff;
    background: #040404;

    @media screen and (max-width: 768px){
        padding: 24px 0;
    }

    @media screen and (max-width: 768px){
        padding: 24px 0;
    }
`;

export const TeamWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

export const TeamRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: start;
    grid-template-areas:'col2 col1';
    margin-bottom: 80px;

    @media screen and (max-width: 768px){
        grid-template-areas: 'col2' 'col1';
        margin-bottom: 24px;
    }   
`;

export const Column1 = styled.div`
    grid-area: col1;
`; 

export const Column2 = styled.div`
    padding: 0px 40px 0px 24px;;
    grid-area: col2;
`; 


export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 0;
`;



export const Subtitle = styled.p`
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom:16px;

    @media screen and (max-width: 960px){  
        font-size: 18px;
        line-height: 24px;
    }  
    
    @media screen and (max-width: 380px){  
        font-size: 18px;
        line-height: 24px;
    } 
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    height: auto;
`;

export const TeamVideo=styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    margin-top:-10px;
    

    @media screen and (max-width: 1200px){  
        margin-top: 0px;
    } 
`

export const TeamInfoWrapper = styled.div`
display: grid;
z-index: 1;
height: auto;
width: 100%;
max-width: 1200px;
margin-right: auto;
margin-left: auto;
padding: 0 24px;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: start;
margin-top: 40px;
@media screen and (max-width: 480px){  
    margin-top: 0px;
} 



`

export const TeamInfo = styled.div`
    max-width: 600px;

`



export const TeamCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    padding: 0 24px;

    @media screen and (max-width: 768px){
       
    }

    @media screen and (max-width: 480px){
        
    }

`;


export const TeamCardsWrapper = styled.div`
    margin: 0 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: start;
    grid-gap: 24px;
    max-width:1200px;
    padding-bottom:40px;

    @media screen and (max-width: 780px){
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 480px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }

    @media screen and (max-width: 380px){
        grid-template-columns: 1fr ;
        padding: 0 20px;
    }
    
`;

export const TeamCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    transition: all 0.2s ease-in-out;

    &:hoover {
        transform: scale(1.02);
        transition: all 0.2 s ease-in-out;
        cursor: pointer;
    }
`;

export const TeamIcon = styled.img`
        height: auto;
        width: 100%;
        margin-bottom: 16px;

`;