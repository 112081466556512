import styled from 'styled-components';

export const MekaLogo = styled.img`
    display: table;
    margin: auto;
    margin-bottom: 80px;
`;

export const ConnectWallet = styled.button`
    background: #FFF;
    color: #000;
    border: 0;
    padding: 10px 40px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    border: 2px solid #CCC;
`;

export const Container = styled.div`
    width: 1200px;
    margin: auto;
    max-width: 100%;
`;
export const Wrapper = styled.div`
    background: #040404;
    color: #FFF;

    a {
        color: #FFF;
    }
`;

export const Section = styled.div`
  position: relative;
  margin: 90px auto 0;

  @media (min-width: 1025px) {
    margin: 120px auto 0;
  }
`;

export const Title = styled.h2`
  text-align: left;
  font-size: 32px;
  font-weight: 800;
  line-height: 0.97;
  text-transform: uppercase;
  width: 100%;
  max-width: 342px;
  margin: 0 auto 36px;

  @media (min-width: 1025px) {
    max-width: 1079px;
    font-size: 42px;
    margin: 0 0 16px;
  }
`;

export const Text = styled.div`
  width: 100%;
  max-width: 342px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.94;
  margin: 0 auto;

  @media (min-width: 1025px) {
    max-width: 642px;
    margin: 0;
  }
`;

export const BoxGuardians = styled.div`
  margin-top: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: 1025px) {
    margin-top: 67px;
    justify-content: space-between;
    margin-bottom: -50px;
  }
`;

export const Item = styled.div`
  width: 100%;
  max-width: 257px;
  height: 435px;

  img {
    max-width: 100%;
  }

  h3 {
    margin: 16px 0;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.77;
  }

  ul {
    border-top: 1px solid #4b4b4b;
    li {
      height: 60px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid #4b4b4b;
      }

      a,
      button {
        transition-duration: 0.3s;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 55px;
  }
`;