import styled from "styled-components";

export const CarouselContainer = styled.div`
    background: #040404;
    height: auto;
    padding: 24px 0 40px 0;


`;

export const GalleryCard = styled.div`
padding: 0px;
display: flex;
align-items: center;
padding: 3%;
`


export const GalleryImg=styled.img`
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    
`