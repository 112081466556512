import React, { FC, useState, useEffect } from 'react';
import { Section, Title, Text, BoxGuardians, Item, Container, Wrapper, ConnectWallet, MekaLogo } from './index.styled';
import logo from '../../img/mekaformers_logo.png'
import {ethers} from 'ethers';

const Mekaformer = ({ title, text }) => {

  const [wallet, setWallet] = useState(false);
  const [guardians, setGuardians] = useState([]);

  useEffect(() => {
    connectMetamask();
  }, []);

  const connectMetamask = async (e) => {
    if(e) e.preventDefault();
    if (window.ethereum) {
      try {
        await window.ethereum.enable();
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const network = await provider.getNetwork();
        const assets = await provider.getSigner().getAddress();
        if (assets) {
          setWallet(assets);
          loadGuardians(assets);
        }
      } catch(error) {
        console.log(error);
      }
    } else {
      alert('You need to install metamask');
      return false;
    }
  };

  const loadGuardians = async(wallet) => {
    const saved = [];
    let req = await fetch(`https://api.opensea.io/api/v1/assets?owner=${wallet}&asset_contract_address=0x9b91d50c90dc2bee416562c102387ab7f0db8fd9&order_direction=desc`)
    let assets = await req.json();
    let guardians = assets.assets;
    for(var i=0; i<guardians.length;i++){
      const guardian = guardians[i];
      saved.push({
        token: guardian.token_id,
        name: guardian.name,
        thumb: guardian.image_url,
        assetImg: `https://mekaformers.s3.amazonaws.com/${'_'+guardians[i].image_original_url.split('.com/')[1].split('_.png')[0]+'.0001.png'}`
      })
    }

    let req2 = await fetch(`https://api.opensea.io/api/v1/assets?owner=${wallet}&asset_contract_address=0x9b91d50c90dc2bee416562c102387ab7f0db8fd9&order_direction=desc`)
    let assets2 = await req2.json();
    let guardians2 = assets2.assets;
    if(guardians2 == 12313){
      for(var i=0; i<guardians2.length;i++){
        const guardian = guardians2[i];
        saved.push({
          token: guardian.token_id,
          name: guardian.name,
          thumb: guardian.image_url,
          assetImg: `https://metag-highres.s3.sa-east-1.amazonaws.com/${'_'+guardians[i].image_original_url.split('.com/')[1].split('_.png')[0]+'.0001.png'}`
        })
      }
    }

    setGuardians(saved);
  }

  async function downloadImage(imageSrc, filename) {
    const image = await fetch(imageSrc)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
  
    const link = document.createElement('a')
    link.href = imageURL
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }


  return (
    <Section>
      <Wrapper>
        <Container>
            <a href="/">
            <MekaLogo src={logo} type='logo_blue/png'/>
            </a>
          <Title>MY DOWNLOADS</Title>
          <Text>Download your blender/unreal assets.</Text>
          <BoxGuardians>
            {wallet && guardians?.map((item, index) => {
                return (
                    <Item key={index}>
                        <img src={item.thumb} alt={item.name} />
                        <h3>{item.name}</h3>
                        <ul>
                        <li>
                            <a href={"https://mekaformers.s3.amazonaws.com/blender/"+item.assetImg.split('amazonaws.com/')[1].replace('.gif.0001.png', '.fbx').substring(1)} target="_blank">
                            <span>Download blender file</span>
                            </a>
                        </li>
                        <li>
                            <a href={"https://mekaformers.s3.amazonaws.com/unreal/"+item.assetImg.split('amazonaws.com/')[1].replace('.gif.0001.png', '.fbx').substring(1)} target="_blank">
                            <span>Download unreal file</span>
                            </a>
                        </li>
                        </ul>
                    </Item>
                );
            })}
            {!wallet && 
                <ConnectWallet onClick={() => {connectMetamask()}}>connect your wallet</ConnectWallet>
            }
          </BoxGuardians>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Mekaformer;
