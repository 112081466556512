import React from 'react'
import { Headerthree, Number, ParagraphTwo } from '../Text/TextsElements'

import {
    Spacebuttom,
    CardContainer,
    CardWrapper,
    Line,
    CardHeader,
    DescriptionWrapper,
    HeaderSeparation
    
}from './CardRoadmapElements'

const RoamapCard = ({
    
    topLine,
    header,
    description
 
}) => {
    return (
        <>
        <CardContainer>
            <CardHeader>
                <Number>{topLine}</Number>
                <HeaderSeparation><Headerthree>{header}</Headerthree></HeaderSeparation>
            </CardHeader>
            <CardWrapper>
                <Line/>
                <DescriptionWrapper>
                    <ParagraphTwo>{description}</ParagraphTwo>
                </DescriptionWrapper>
            </CardWrapper>  
        </CardContainer>
        <Spacebuttom/>
        </>
        
    )
}

export default RoamapCard
