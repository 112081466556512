import React from 'react'
import VideoTeam from '../../img/Team_reel.mov'
import CoverTeamReel from '../../img/TeamReel_Cover.png'
import {
    Player,
    ControlBar,
  } from 'video-react';

const TeamVideoReel = () => {
    return (
        <Player
            autoPlay
            muted
            loop="true"
            poster={CoverTeamReel}
            src={VideoTeam}
            playsInline="true" >
              
            <ControlBar disableCompletely={true}>
            </ControlBar>
        </Player>
  );
}

export default TeamVideoReel
