import React, { Component } from "react";
import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from '../../img/Gallery/still_01.png';
import img2 from '../../img/Gallery/still_02.png';
import img3 from '../../img/Gallery/still_03.png';
import img4 from '../../img/Gallery/still_04.png';
import img5 from '../../img/Gallery/still_05.png';
import img6 from '../../img/Gallery/still_06.png';
import img7 from '../../img/Gallery/still_07.png';
import img8 from '../../img/Gallery/still_08.png';
import img9 from '../../img/Gallery/still_09.png';
import img10 from '../../img/Gallery/still_10.png';

import{
  CarouselContainer,
  GalleryCard,
  GalleryImg
} from './GalleryElements';


export default class Gallery extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 3,
      speed: 400,
      dots: true,
      arrows:false,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };
    return (
        <CarouselContainer id="Gallery">
            <Slider {...settings}>

                <GalleryCard><GalleryImg src={img1} type='still_01/png'/></GalleryCard>
                <GalleryCard><GalleryImg src={img2} type='still_02/png'/></GalleryCard>
                <GalleryCard><GalleryImg src={img3} type='still_03/png'/></GalleryCard>
                <GalleryCard><GalleryImg src={img4} type='still_04/png'/></GalleryCard>
                <GalleryCard><GalleryImg src={img5} type='still_05/png'/></GalleryCard>
                <GalleryCard><GalleryImg src={img6} type='still_06/png'/></GalleryCard>
                <GalleryCard><GalleryImg src={img7} type='still_07/png'/></GalleryCard>
                <GalleryCard><GalleryImg src={img8} type='still_08/png'/></GalleryCard>
                <GalleryCard><GalleryImg src={img9} type='still_09/png'/></GalleryCard>
                <GalleryCard><GalleryImg src={img10} type='still_10/png'/></GalleryCard>
                
            </Slider>
      </CarouselContainer>
    );
  }
}