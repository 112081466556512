import React from 'react';
import Home from './pages';

import './App.css';

function App() {
  return (
    <Home/>
  );
}

export default App;