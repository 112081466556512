import styled from "styled-components";

export const CommunityContainer = styled.div`
    color: #fff;
    background: #040404;
    position: relative;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    max-height: 580px;
    padding-top: 120px;
    padding-bottom: 40px;
    @media screen and (max-width: 768px){
        padding: 24px 0;
    }
`;


export const InfoWrapper = styled.div`
    position: absolute;
    display: grid;
    z-index: 1;
    height: auto;
    left: 0;
    right: 0;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas:'col2 col1';
    position: absolute;
    z-index: 4;
    padding: 40px;

    @media screen and (max-width: 768px){
        grid-template-areas: 'col1' 'col2';
        margin-bottom: 24px;
        
    }   
`;

export const Column1 = styled.div`
    margin:0px;
    padding: 0 0px;
    grid-area: col1;
`; 

export const Column2 = styled.div`
    margin-bottom: 0px;
    padding: 0px;
    grid-area: col2;
    @media screen and (max-width: 780px){
        padding-right: 180px;
    }
    
    @media screen and (max-width: 720px){  
        padding: 0;
    } 
`; 


export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 0;
    @media screen and (max-width: 760px){  
        padding: 0 200px 0 0;
    }

    @media screen and (max-width: 720px){  
        padding: 0;
    } 

    
    
`;



export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 580px;
    height: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0px;
    padding-right: 0;
`;

export const CommunityBg=styled.div`
width: 100%;
height: 580px;
margin-bottom: 0px;
position: relative;
bottom: 0;
`

export const CommunityImg=styled.img`
    width: 100%;
    height: 580px;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 1200px){  
        margin-top: 0px;
    } 

    
`

export const ButtonContainer = styled.div`
    
    background: white;
    color:black;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.17);
    padding: 0px 24px;
    height: 48px;
    margin: 16px 0px;
    display: flex;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 240px;

    &:hover{
        border: none;
        background: #040404;
        color: white;
    }


    

`

export const ButtonLink = styled.a`
    text-decoration-line: none;
    text-decoration: none;
    color: white;

`

export const CommunityButtonContainerLabel = styled.p`
font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
   
`