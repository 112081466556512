import React from 'react'
import logo from '../../img/mekaformers_logo.png'
import opensealogo from '../../img/social/openSea_logo.svg';
import twitterlogo from '../../img/social/twitter_logo.svg';
import discordlogo from '../../img/social/discord_logo.svg';

import { 

    FooterContainer,
    Logo,
    Logoimg,
    

 } from './FooterElements'
import { ButtonLink, Link, Links, SocialIcon, SocialNav } from '../hero/HeroElements';
import { ParagraphTwoCenter } from '../Text/TextsElements';

const FooterSection = () => {
    return (
        <FooterContainer id="Footer">
            
            <Logo>
                    <Logoimg src={logo} type='logo_pink/png'/>
            </Logo>
            <ParagraphTwoCenter>©2021 Mekaformers. All rights reserved.</ParagraphTwoCenter>

            <Links>
                    <SocialNav>
                    <ButtonLink href="https://opensea.io/collection/mekaformers" target="_blank" rel="noreferrer" >
                        <Link>
                            <SocialIcon src={opensealogo} type='opensealogo/svg'/>
                        </Link>
                    </ButtonLink>
                    <ButtonLink href="https://twitter.com/mekaformers" target="_blank" rel="noreferrer" >
                        <Link>
                            <SocialIcon src={twitterlogo} type='twitterlogo/svg'/>
                        </Link>
                    </ButtonLink>
                  
                    <ButtonLink href="https://discord.com/invite/Mekaformers" target="_blank" rel="noreferrer" >
                        <Link>
                            <SocialIcon src={discordlogo} type='discordlogo/svg'/>
                        </Link>
                    </ButtonLink> 
                    </SocialNav>
            </Links>            
            
        </FooterContainer>

    
    )
}

export default FooterSection
