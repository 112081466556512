import React from 'react'

import {RoadmapContainer, RoapmapWrapper, RoapmapInfo, DarkBlueSection, RoadmapChrono, RoadBG, RoadmapImg } from './RoadmapElements.js';
import roadmapBgimg from '../../img/roadmapimg.png'

import RoamapCard from '../CardRoadmap/Index.js';
import { RoadmapFour, RoadmapFive, RoadmapOne, RoadmapSix, RoadmapThree, RoadmapTwo, RoadmapSeven, RoadmapEight } from '../CardRoadmap/CardRoadMapData.js';
import { Headertwo, ParagraphOne } from '../Text/TextsElements.js';



const Roadmap = () => {
    return (
        <>
        <RoadmapContainer id= "Roadmap">
        <DarkBlueSection>
            <RoapmapWrapper>
                <RoapmapInfo>
                <Headertwo>Roadmap</Headertwo>
                <ParagraphOne>This roadmap covers from the very beginning of the launch to our vision for the future of Mekaformers. Since our assets were made using Unreal Engine 5 we are ahead in terms of development, granting us a competitive advantage within the market.</ParagraphOne>
                </RoapmapInfo>
            </RoapmapWrapper>

                <RoadmapChrono>
                    <RoamapCard {...RoadmapOne}/>
                    <RoamapCard {...RoadmapTwo}/>
                    <RoamapCard {...RoadmapThree}/>
                    <RoamapCard {...RoadmapFour}/>
                    <RoamapCard {...RoadmapFive}/>
                    <RoamapCard {...RoadmapSix}/>
                    <RoamapCard {...RoadmapSeven}/>
                    <RoamapCard {...RoadmapEight}/>
                </RoadmapChrono>

                <RoadBG>
                    <RoadmapImg src={roadmapBgimg} type='roadmapimg/png'/>
                </RoadBG>
            </DarkBlueSection>
        </RoadmapContainer>
        </>
    )
}

export default Roadmap
