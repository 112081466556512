import styled from "styled-components";

export const CardContainer = styled.div`
align-items: center;
max-width: 712px;
padding: 24px;

background: #08191F;
box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.13);

@media screen and (max-width: 960px){  
    max-width: 500px;
}  

@media screen and (max-width: 768px){
    padding-right: 16px;

    max-width:712px;
}`;

export const CardHeader =styled.div`
    align-items: flex-end;
    display: flex;
    margin-bottom: 16px;
    
`
export const HeaderSeparation = styled.div`
    margin-left: 16px;
`


export const Spacebuttom = styled.div`
    width:1px;
    height: 40px;
    margin-left: 64px;
`
export const Line = styled.div`
    width:1px;
    min-width: 1px;
    background-color: white;
`


export const LineTop = styled.div`
    width:1px;
    background-color: white;
    height: 24px;
    margin-left: 64px;
`
export const CardWrapper =styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 16px;
`

export const DescriptionWrapper = styled.div`
margin-left: 32px;

`

export const Description = styled.p`
    color: #8E96A6;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
`