import styled from 'styled-components';

export const HeroContainer = styled.div`
    background: #040404;
    display: flex;
    justify-content: center;
    // align-items: center;
    padding: 0px;
    height: auto;
    z-index:1;

    // @media screen and (min-width: 1920px){  
    //     height: 1000px;
    // } 

    // @media screen and (min-width: 2560px){  
    //     height: 1000px;
    // } 

    // @media screen and (min-width: 3840px){  
    //     height: 1500px;
    // } 

    // @media screen and (min-width: 5640px){  
    //     height: 2600px;
    // } 

    // @media screen and (min-width: 7680px){  
    //     height: 3200px;
    // } 

    // @media screen and (max-width: 380px){  
    //     height: 400px;
    // } 
`
export const HeroBg=styled.div`
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   overflow:hidden;
   `

export const HeroVideo=styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    margin-top:-10px;
    

    @media screen and (max-width: 1200px){  
        margin-top: -10px;
    } 
`

export const HeroInfo = styled.div`
    position: absolute;
    z-index: 2;
    justify-content: center;
    display: flex;

    align-items: end;
    padding-top: 32%;

`

export const Navbar=styled.div`
position: absolute;
left: 0;
right: 0;
z-index: 2;
display: flex;
flex-direction: row;
max-width: 1200px;
padding: 24px 24px 0px;
margin: auto;
justify-content: space-between;
align-items: center;

@media screen and (max-width: 960px){  
    padding: 24px 24px 0px 24px;
}  

@media screen and (max-width: 560px){  
    padding: 24px 8px 0px 8px;
} 

`

export const Logo=styled.div`
    position: static;
    width: 64px;
    height: 24px;
    left: 0px;
    top: 0px;

    @media screen and (max-width: 960px){  
        height: 24px;
        width: 64px;
    }
    @media screen and (max-width: 380px){  
        height: 24px;
        width: 48px;
        margin-left: 8px;
    } 

    
`

export const Logoimg=styled.img`
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
`

export const Links=styled.div`

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

`
export const SocialNav= styled.div`
    display: flex;
    flex-direction: row;
`

export const Link=styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;

    margin: 0px 8px;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;

    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 0px;

    &:hover{
        border: 1px solid #FFFFFF;
        background: #1C0C4C;
    }

    @media screen and (max-width: 960px){  
        width: 32px;
        height: 32px;
        padding: 6px;
        margin: 0 4px;
    }  

    @media screen and (max-width: 380px){  
        width: 24px;
        height: 24px;
        padding: 4px;
        margin: 0 4px;
    } 
`

export const SocialIcon=styled.img`
    width: 100%;
    height: auto;
`

export const ButtonContainer = styled.div`
    background: #040404;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.17);
    border-radius: 0px;
    padding: 0px 24px;
    height: 40px;
    margin: 0px 24px;
    display: flex;
    align-items: center;
    text-align: center;

    &:hover{
        border: 1px solid #FFFFFF;
        background: #1C0C4C;
    }

    @media screen and (max-width: 960px){  
        padding: 0px 16px;
        height: 32px;
        margin: 0px 8px;
    }  

    @media screen and (max-width: 380px){  
        padding: 0px 8px;
        height: 24px;
        margin: 0px 8px;
    } 
    

`

export const ButtonLink = styled.a`
    text-decoration-line: none;
    text-decoration: none;
    color: white;
`

export const ButtonContainerLabel = styled.p`
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;

    @media screen and (max-width: 960px){  
        font-size: 16px;
    } 

    @media screen and (max-width: 560px){  
        font-size: 12px;
    } 

    @media screen and (max-width: 380px){  
        font-size: 11px;
    } 
   
`


export const ButtonMint = styled.div`
    background: #040404;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.17);
    border-radius: 0px;
    padding: 0px 56px;
    height: 40px;
    margin: 0px 24px;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
`
export const Heroimg=styled.img`
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    margin-top: -180px;
    

    @media screen and (max-width: 1200px){  
        margin-top: 0px;
    } 
`