import React from 'react'
import { ParagraphTwo, Headertwo, Headerthree } from '../Text/TextsElements'

import Team1 from '../../img/Team/Daniel.png';
import Team2 from '../../img/Team/Davide.png';
import Team3 from '../../img/Team/avatar_hector.png';
import Team4 from '../../img/Team/avatar_pedro.png';


import {
    TeamContainer,
    TeamWrapper,
    TeamRow,
    Column1,
    Column2,
    TextWrapper,
    TeamInfoWrapper,
    TeamInfo,
    TeamCardsContainer,
    TeamCardsWrapper,
    TeamCard,
    TeamIcon
 } from './TeamElements'
import TeamVideoReel from '../TeamVideo/TeamVideo';

const Team = () => {
    return (
        <>
            <TeamContainer id="Team">

            
                <TeamWrapper>
                    <TeamInfoWrapper>
                            <TeamInfo>
                                <Headertwo>MEET THE TEAM</Headertwo>
                            </TeamInfo> 
                    </TeamInfoWrapper>
                                        
                            <TeamRow>
                                <Column1>
                                    {/* <TeamVideo autoPlay loop muted src={Video} type='video/mov'/> */}
                                    <TeamVideoReel/>
                                </Column1>
                                <Column2>
                                    <TextWrapper>
                                        <ParagraphTwo>Daniel Favini and Davide Prato are 2 friends that have been working together for a long time, they are the direct cause of the Jaeger Scrapper itself from Pacific Rim!</ParagraphTwo>
                                        <ParagraphTwo>Daniel Favini has on his back award-winning films starting from Matrix to Avengers: Endgame, you can check his portfolio here.</ParagraphTwo>
                                        <ParagraphTwo>Davide Prato has been working with Christopher Nolan himself and of course on films such as Avengers or even the latest James bond film: No time to die, you can check his portfolio here.</ParagraphTwo>
                                        <ParagraphTwo>Now they are Bond together to bring you the ultimate experience within the NFT industry!</ParagraphTwo>
                                    </TextWrapper>
                                </Column2>
                                
                            </TeamRow>
                </TeamWrapper>

                <TeamCardsContainer>
                    <TeamCardsWrapper>
                        <TeamCard>
                            <TeamIcon src={Team1}/>
                            <Headerthree>Daniel Favini</Headerthree>
                            <ParagraphTwo>Lead Artist</ParagraphTwo>
                        </TeamCard>
                        <TeamCard>
                            <TeamIcon src={Team2}/>
                            <Headerthree>Davide Prato</Headerthree>
                            <ParagraphTwo>Lead technical Artist </ParagraphTwo>
                            </TeamCard>
                        <TeamCard>
                            <TeamIcon src={Team3}/>
                            <Headerthree>Hector Z.</Headerthree>
                            <ParagraphTwo>Engineer, Project Management</ParagraphTwo>
                        </TeamCard>
                        <TeamCard>
                            <TeamIcon src={Team4}/>
                            <Headerthree>Pedro K.</Headerthree>
                            <ParagraphTwo>SFX, Production</ParagraphTwo>
                        </TeamCard>
                    </TeamCardsWrapper>
                </TeamCardsContainer>
            </TeamContainer>
        
        </>
    )
}

export default Team
