import styled from "styled-components";

export const RoadmapContainer = styled.div`
    color: #fff;
    background: #040404;

    @media screen and (max-width: 768px){
        padding: 24px 0;
    }

    @media screen and (max-width: 768px){
        padding: 24px 0;
    }
`

export const DarkBlueSection = styled.div`
    color: #fff;
    background: #040404;
    position: relative;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding-bottom:40px;

    @media screen and (max-width: 768px){
        padding: 24px 0;
    }
`

export const RoapmapWrapper = styled.div`
display: grid;
z-index: 1;
height: auto;
width: 100%;
max-width: 1200px;
margin-right: auto;
margin-left: auto;
padding: 0 24px;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: start;
margin-top: 40px;
margin-bottom: 32px;

`

export const RoapmapInfo = styled.div`
    max-width: 600px;

`



export const RoadmapChrono = styled.div`
    display: flex;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0 24px;
    justify-content: start;
    flex-direction: column;
    padding-left: 58px;

    @media screen and (max-width: 768px){
        padding: 24px;
    }

`

export const RoadBG=styled.div`
width: auto;
height: 100%;
margin-bottom: 0px;
position: absolute;
bottom: 0;
right: 0;
display: flex;
align-items: center;
`

export const RoadmapImg=styled.img`
    width: 100%;
    height: 1200px;
    -o-object-fit: cover;
    object-fit: cover;
  
}

    @media screen and (max-width: 1200px){  
        margin-top: 0px;
    } 

    @media screen and (max-width: 768px){  
        display:none;
    } 
`


