import React, {useState, useEffect} from 'react';
import { ethers } from "ethers";
import Web3 from 'web3';
import contract from '../../contracts/index';
import styles from '../../Buy.module.css';
import logo from '../../img/mekaformers_logo.png';
import gif from '../../img/welcomeGIF.gif';

export default function Public() {

  const [wallet, setWallet] = useState(null);
  const [minted, setMinted] = useState(0);
  const [qty, setQty] = useState(1);

  useEffect(() => {
    // checkAssetsMinted();
    connectMetamask();
  }, [])

  async function checkAssetsMinted() {
    try {
        const alchemy = 'https://eth-mainnet.alchemyapi.io/v2/7OKRf6W8FI7SOF5GHmuja1KhdagMnbzS';
        const provider = new ethers.providers.JsonRpcProvider(alchemy);
        const contractAddress = '0x1799FCa575F301EB5191b4F0272beFBfd8D03A62';
        const ct = new ethers.Contract(contractAddress, contract.abi, provider);
        let count = await ct.nonce();
        count = String(count);
        if(count > 0) {
            setMinted(count)
        }
    } catch(err) {
        console.log(err)
    }
  }

  const connectMetamask = async (e) => {
    if(e) e.preventDefault();
    if (window.ethereum) {
      try {
        await window.ethereum.enable();
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const network = await provider.getNetwork();
        const assets = await provider.getSigner().getAddress();

        if (assets) {
          setWallet(assets);
        }
      } catch(error) {
        console.log(error);
      }
    } else {
      alert('You need to install metamask');
      return false;
    }
  };

  async function canMint() {
    if (window.ethereum) {
        try {
        await window.ethereum.enable()
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const network = await provider.getNetwork();

        if (network?.name != 'homestead' ) {
            alert('You should connect your wallet to Ethereum Mainnet network');
            return false;
        }

        return provider.getSigner();
        } catch(error) {
        return false;
        }
    } else {
        alert('You need to install metamask');
        return false;
    }
  }

  return (
    <div className={styles.wrapper}>
      <main className={styles.main}>
        <div className={styles.pageBuy}>
          <div className={styles.container}>
            <header className={styles.header}>
              
            </header>
          </div>
          <div className={styles.container}>
            <section className={styles.contentInfo}>
              <div className={styles.desktop}>
                <div className={styles.info}>
                <a href="/">
                    <img className={styles.img} src={logo} />
                </a>
                  <img className={styles.img} src={`https://mekaformers.s3.amazonaws.com/legacy/legacy-placeholder.gif`} />
                  {/* <span className={styles.name}>{minted}/4400 minted</span> */}
                </div>
              </div>
              <div className={styles.wallet}>
                <h2 className={styles.title}><span>Mekaformers Legacy</span><br/>PUBLIC SALE</h2>
                <p className={styles.description}>Price: 0.08 ETH + gas/each.<br/>Max: 10/tx.</p>
                {(wallet == null) ? (
                  <React.Fragment>
                    <span onClick={(e) => connectMetamask(e)}className={styles.button}><b>Connect your wallet</b></span>
                    <span className={styles.desconectedWallet}>
                      <img width="14" height="14" src="/redClose.png" />
                      Disconnected Wallet
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <form className={styles.formByNft}>
                      <input className={styles.input} type="number" min="1" max="10" placeholder="Quantity" value={qty} onChange={(e) => setQty(e.target.value)}/>
                      <a href="#" className={styles.button} onClick={ async (e) => {
                                e.preventDefault();

                                if (qty < 1 || qty > 10) {
                                  return;
                                }

                                const signed = await canMint();

                                if (!signed) {
                                    return;
                                }

                                const ct = new ethers.Contract(
                                    '0x1799FCa575F301EB5191b4F0272beFBfd8D03A62',
                                    contract.abi,
                                    signed
                                );

                                const price = 0.08;
                                const total = String(price * qty);
                                try {
                                    const tx = await ct.buy(qty, {from: signed.getAddress(), value: Web3.utils.toWei(`${total}`, 'ether') });
                                    if (tx.hash) {
                                        alert('Transaction submitted successfully. You should check your opensea wallet after it gets confirmed.');
                                    }
                                } catch (err) {
                                  console.log('err', err.message.includes);
                                    if (err.message.includes('denied')) {
                                        alert('The transaction was cancelled.');
                                    } else if (err.message.includes('sale is not active')) {
                                      alert("sale is not active! ");
                                    }  else if (err.message.includes("You can't mint on presale")) {
                                      alert("You can't mint that amount on presale");
                                    } else if (err.message.includes('invalid value')) {
                                      alert('Payment: Invalid value');
                                    }
                                    else {
                                        if ( err.message.indexOf('insufficient funds') >= 0 ) {
                                            alert('Insufficient funds, the price is 0.08 eth (each) + gas!')
                                        }
                                    }
                                }
                                }}><b>MINT</b></a>
                    </form>
                    <p className={styles.explain}>
                      Once minted, please allow a few moments for the transaction to complete. You can see your Mekaformers on <a className={styles.link} href="https://opensea.io/collection/mekaformers-legacy">Opensea</a>.
                    </p>
                  </React.Fragment>
                )}
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  )
}