export const RoadmapOne ={
    topLine: '.01',
    header: 'Launch',
    description: 'Utility comes first. Mekaformers were made in Unreal Engine 5, we have all assets rigged, game and metaverse-ready so from the very beginning you will be owning an asset that already has further utility within both metaverse and gaming areas that come along with full commercial rights.The drop will be announced as we grow together, we want a community as solid as the materials our Mekaformers are made out of!',
};

export const RoadmapTwo ={
    topLine: '.02',
    header: 'Unreal Engine 5 ( Ready )',
    description: 'Mekaformers are ready to be used in Unreal Engine 5, 1 week after the launch we will be uploading the files to the website for every holder that wants either to build a game or explore the capabilities their Mekaformers allow in Unreal Engine 5, every contribution to our universe is always welcome. Mekaformers are ready to be used as the community pleases!',
};

export const RoadmapThree ={
    topLine: '.03',
    header: 'Blender rigged bodies ( Ready )',
    description: 'Of course we know there are a lot of incredible people with amazing skills within NFT space, so 2 weeks after launch we will be uploading blender files to our website the owners of Mekaformers can claim for free!',
};

export const RoadmapFour ={
    topLine: '.04',
    header: 'Mekaformers: LEGACY',
    description: 'Mekaformers LEGACY are the second drop from Mekaformers, they will grant you access to the upcoming play-to-earn as well as benefits such as staking to gain $FUEL, Mekaformer’s token. Legacy also makes you eligible to participate in $FUEL airdrop.',
};

export const RoadmapFive ={
    topLine: '.05',
    header: 'Staking ($FUEL)',
    description: 'We are developing our STAKING feature.You will be able to stake your GENESIS and LEGACY NFTs, and therefore earn $FUEL, Mekaformer´s token. You will be able to spend $FUEL in "The Armory" and craft different equipment (guns, shields, and all kind of weapons ) to improve the stats of your in-game characters from Mekaformers World. This equipment will be crucial in the future P2E game.',
};

export const RoadmapSix ={
    topLine: '.06',
    header: 'Metaverse Avatars & Wearables',
    description: 'As we have everything prepared, metaverse avatars are a natural step for the Mekaformers universe, it will take us up to 4 weeks to introduce Mekaformers in the first of the many metaverses we want to introduce them in!',
};


export const RoadmapSeven ={
    topLine: '.07',
    header: 'Play-to-earn',
    description: 'All Mekaformers are ready-to-go and fully working in Unreal Engine 5, our next step is to build a play-to-earn game with them!.We are so excited to talk with you about it. We will also be exploring other genres apart from main game that could fit into our world to bring Mekaformers further and keep developing titles connected with the same technology!',
};



export const RoadmapEight ={
    topLine: '.08',
    header: 'Big Screen',
    description: 'Our team features the artists in charge of the most successful movies the world has seen within the past 18 years. Not only our skills make us the perfect fit to be capable of producing a movie or a series but we’ve been gathering a strong network within the Hollywood industry throughout our lives! It would be amazing to lead Mekaformers into the Big Screen!',
};












 







