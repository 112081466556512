import React from 'react';
import { HeaderOne, HeaderOneGreen, ParagraphOne } from '../Text/TextsElements';


import { 

    WelcomeContainer,
    InfoWrapper,
    Headerone,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    ImgWrap,
    Img,
    ButtonLink,
    ButtonMint,
    ButtonContainerLabel
 } from './WelcomeElements'

const WelcomeSection = ({ 
    id,
    headingh1,
    headingh2,
    pone,
    ptwo,
    imgStart,
    img,
    alt,
    
    }) => {
    return (
            <>
                <WelcomeContainer id={id}>
                    <InfoWrapper>
                        <InfoRow imgStart={imgStart}>
                            <Column1>
                                <ImgWrap>
                                    <Img src={img} alt ={alt} />
                                </ImgWrap>
                            </Column1>
                            <Column2>
                                <TextWrapper>
                                    <Headerone>
                                    <HeaderOneGreen>{headingh1}</HeaderOneGreen>
                                    <HeaderOne>{headingh2}</HeaderOne>
                                    </Headerone>
                                    <ParagraphOne>{pone}</ParagraphOne>
                                    <ParagraphOne>{ptwo}</ParagraphOne>
                                </TextWrapper>
                                <ButtonLink href="https://opensea.io/collection/mekaformers" target="_blank" rel="noreferrer" >
                                    <ButtonMint>
                                        <ButtonContainerLabel>BUY ON OPENSEA</ButtonContainerLabel>
                                    </ButtonMint>
                                </ButtonLink>
                            </Column2>
                            
                        </InfoRow>
                    </InfoWrapper>
                </WelcomeContainer>
            </>
    )
}

export default WelcomeSection
