import React from 'react'
import logo from '../../img/unrealengine5logowhite.png'
import opensealogo from '../../img/social/openSea_logo.svg';
import twitterlogo from '../../img/social/twitter_logo.svg';
import discordlogo from '../../img/social/discord_logo.svg';


import { 
    HeroContainer,
    HeroInfo,
    HeroBg,
    Heroimg,
    Navbar,
    Logo,
    Logoimg,
    Links,
    SocialNav,
    Link,
    SocialIcon,
    ButtonContainer,
    ButtonContainerLabel,
    ButtonLink,
    ButtonMint
   
 } from './HeroElements'
 import img from '../../img/Mekaformers_header.png'
// import HeroVideoComponent from '../HeroVideo/Index';


const Hero = () => {
    return (
        <HeroContainer id= "hero">
            <Navbar>
                <Logo>
                    <Logoimg src={logo} type='logo_blue/png'/>
                </Logo>
                <Links>
                    <SocialNav>
                    <ButtonLink href="https://opensea.io/collection/mekaformers" target="_blank" rel="noreferrer" >
                        <Link>
                            <SocialIcon src={opensealogo} type='opensealogo/svg'/>
                        </Link>
                    </ButtonLink>
                    <ButtonLink href="https://twitter.com/mekaformers" target="_blank" rel="noreferrer" >
                        <Link>
                            <SocialIcon src={twitterlogo} type='twitterlogo/svg'/>
                        </Link>
                    </ButtonLink>
                  
                    <ButtonLink href="https://discord.com/invite/Mekaformers" target="_blank" rel="noreferrer" >
                        <Link>
                            <SocialIcon src={discordlogo} type='discordlogo/svg'/>
                        </Link>
                    </ButtonLink>

                    <ButtonLink href="https://metamask.io/" target="_blank" rel="noreferrer" onClick={(e) =>e.preventDefault()}>
                        <ButtonContainer>
                            <ButtonContainerLabel>Connect Wallet</ButtonContainerLabel>
                        </ButtonContainer>
                    </ButtonLink>

                    </SocialNav>
                </Links>
               
            </Navbar> 
            <HeroInfo>
                    <ButtonLink href="#" target="_blank" rel="noreferrer" onClick={e => e.preventDefault()}>
                        <ButtonMint>
                            <ButtonContainerLabel>MINT LEGACY</ButtonContainerLabel>
                        </ButtonMint>
                    </ButtonLink>
            </HeroInfo> 
            <HeroBg>
            <Heroimg src={img} type='Mekaformers_header.png'/>
            </HeroBg>
          
        </HeroContainer>
    )
}

export default Hero
