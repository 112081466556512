import React from 'react'
import Hero from '../components/hero/Index.js';
import Holders from '../components/Holders/index';

const MintHolders = () => {
    return (
        <>
            <Holders/>
        </>
    )
}

export default MintHolders;
