import React from 'react'
import Hero from '../components/hero/Index.js';
import Public from '../components/Public/index';

const Mint = () => {
    return (
        <>
            <Public/>
        </>
    )
}

export default Mint;
